<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>
              Get price per kg and per box of product for customer.
            </p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <router-link
                to="/history"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block">
                    View
                  </span>
                  History
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered">
        <div class="card-body">
          <div class="row" v-if="has_error">
            <div class="col-6" v-for="error in errors">
              <div class="alert alert-danger text-danger mb-2">
                <p>{{ error }}</p>
              </div>
            </div>
          </div>
          <form @submit.prevent="makeCalculation" class="row">
            <div class="col-md-5 form-group">
              <label class="control-label">Select Customer</label>
              <Multiselect
                :options="customers"
                v-model="calc.user_id"
                trackBy="name"
                label="name"
                valueProp="id"
                :searchable="true"
                placeholder="Start typing..."
              />
            </div>

            <div class="col-md-5 form-group">
              <label class="control-label">Select Product</label>
              <Multiselect
                :options="products"
                v-model="calc.product_id"
                trackBy="name"
                label="name"
                valueProp="id"
                :searchable="true"
                placeholder="Type product to search..."
                @change="setProduct"
              />
            </div>

            <div class="col-md-2 form-group">
              <label class="control-label">Loose/Prepack</label>
              <Multiselect
                :options="states"
                :searchable="true"
                v-model="calc.state"
                placeholder="Select"
                @change="setPackability"
              />
            </div>

            <div class="col-8 col-md-4 form-group">
              <label class="control-label">Raw Material Costs</label>
              <input
                readonly="readonly"
                id="raw_cost"
                type="number"
                class="form-control form-control-lg"
                name="raw_cost"
                v-model="calc.raw_cost"
                @change="setCosts"
                @keyup="setCosts"
              />
            </div>

            <div class="col-4 col-md-4 form-group">
              <label class="control-label">Packability(%)</label>
              <input
                readonly="readonly"
                id="product_packability"
                type="text"
                class="form-control form-control-lg"
                name="product_packability"
                v-model="calc.product_packability"
                @change="setCosts"
                @keyup="setCosts"
              />
            </div>

            <div
              class="col-9 form-group"
              :class="user.role == 'admin' ? 'col-md-3' : 'col-md-4'"
            >
              <label class="control-label">Total Product Cost</label>
              <input
                readonly="readonly"
                id="total_prod_cost"
                type="text"
                class="form-control form-control-lg"
                name="prod_cost"
                v-model="calc.prod_cost"
              />
            </div>

            <div
              class="col-3 form-group align-items-end text-center mt-1"
              :class="user.role == 'admin' ? 'col-md-1' : 'd-none'"
              v-if="user.role == 'admin'"
            >
              <label
                class="control-label d-none d-sm-inline-block d-md-inline-block"
              >
                Edit
              </label>
              <button
                id="edit_fields"
                class="btn btn-icon btn-round btn-warning btn-rounded"
                @click.prevent="makeEditable"
              >
                <em class="icon ni ni-pen"></em>
              </button>
            </div>

            <div class="col-4 col-md-4">
              <div class="form-group">
                <label class="control-label">Pack Weight(g)</label>
                <input
                  id="pack_weight"
                  type="number"
                  class="form-control form-control-lg"
                  name="pack_weight"
                  v-model="calc.pack_weight"
                  @keyup="setWeight"
                />
              </div>
            </div>

            <div class="col-4 col-md-4">
              <div class="form-group">
                <label class="control-label">Pack Count(Box)</label>
                <input
                  id="pack_count"
                  type="text"
                  class="form-control form-control-lg"
                  name="pack_count"
                  v-model="calc.pack_count"
                  @keyup="setWeight"
                />
              </div>
            </div>

            <div class="col-4 col-md-4 form-group">
              <label class="control-label">Box Weight(Kg)</label>
              <input
                readonly="readonly"
                id="box_weight"
                type="text"
                class="form-control form-control-lg"
                name="box_weight"
                v-model="calc.box_weight"
              />
            </div>

            <div class="col-md-4 form-group">
              <label class="control-label">Primary Packaging</label>
              <Multiselect
                :options="getCosts('primary-packaging')"
                v-model="calc.primary_packaging"
                trackBy="name"
                label="name"
                valueProp="id"
                :searchable="true"
                placeholder="Type to search..."
              />
            </div>

            <div class="col-md-4 form-group">
              <label class="control-label">Labelling Costs</label>
              <Multiselect
                :options="getCosts('labelling')"
                v-model="calc.labelling"
                trackBy="name"
                label="name"
                valueProp="id"
                :searchable="true"
                placeholder="Type to search..."
              />
            </div>

            <div class="col-md-4 form-group">
              <label class="control-label">Box Type</label>
              <Multiselect
                :options="getCosts('box-types')"
                v-model="calc.box_type"
                trackBy="name"
                label="name"
                valueProp="id"
                :searchable="true"
                placeholder="Type to search..."
              />
            </div>

            <div class="col-md-5 form-group">
              <label class="control-label">Shipping Formats</label>
              <Multiselect
                :options="pallets"
                v-model="calc.pallet_type_id"
                trackBy="name"
                label="name"
                valueProp="id"
                :searchable="true"
                placeholder="Type to search..."
              />
            </div>

            <div class="col-md-4 form-group">
              <label class="control-label">Optional Margin (%)</label>
              <input
                type="number"
                class="form-control form-control-lg"
                name="margin"
                v-model="calc.margin"
              />
            </div>
            <div class="col-md-3 pt-5 form-group">
              <button
                type="submit"
                class="btn btn-primary btn-rounded btn-block btn-lg mt-n1"
              >
                <em class="icon ni ni-calc"></em>
                <span>
                  Calculate
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  toRefs,
  reactive,
  computed
} from "vue";

import router from "../../router";
import http from "../../../../../common/services/http";
import store from "../../../../../common/services/store";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: {NgongVegBanner},
  setup() {
    const customers = ref([]);
    const costs = ref([]);
    const products = ref([]);
    const packabilities = ref([]);
    const pallets = ref([]);

    const product = ref({});

    const calc = reactive({
      user_id: 0,
      product_id: 0,
      state: "select",
      raw_cost: 0,
      product_packability: 75,
      prod_cost: "0.0",
      pack_weight: 0,
      pack_count: 0,
      primary_packaging: 0,
      labelling: 0,
      box_type: 1,
      box_weight: "0.0",
      pallet_type_id: 0,
      margin: 0
    });

    const states = ref([
      { value: "select", label: "Select" },
      { value: "loose", label: "Loose" },
      { value: "prepack", label: "Prepack" }
    ]);

    const { user } = toRefs(store.state);

    const has_error = ref(false);
    const errors = ref("");

    const boxWeight = computed(() => {
      return ((calc.pack_weight * calc.pack_count) / 1000).toFixed(2);
    });

    function viewCalculation(result: any) {
      router.replace(`/calculations/${result.id}`);
    }

    function getCosts(type: any) {
      return costs.value?.filter((cost: any) => cost?.category?.slug == type);
    }

    function setCosts() {
      const perc = product.value["packability"] / 100;

      calc.prod_cost = (calc.raw_cost / perc).toFixed(2);
    }

    function setProduct(p: any) {
      calc.product_id = p;

      const pr: any = products.value.find((pt: any) => pt.id == p);
      product.value = pr;
      calc.raw_cost = pr.cost;
    }

    function setWeight() {
      calc.box_weight = boxWeight.value;
    }

    function setPackability(state: any) {
      calc.product_packability = product.value["packability"];

      setCosts();
    }

    function makeCalculation() {
      has_error.value = false;
      errors.value = "";
      http
        .post("/api/calculations", calc)
        .then((res: any) => {
          router.push({
            path: `/calculations/${res.data.id}`,
            query: {
              user: calc.user_id,
              product: calc.product_id,
              state: calc.state,
              raw_cost: calc.raw_cost,
              packability: calc.product_packability,
              cost: calc.prod_cost,
              weight: calc.pack_weight,
              count: calc.pack_count,
              primary: calc.primary_packaging,
              labelling: calc.labelling,
              type: calc.box_type,
              box_weight: calc.box_weight,
              pallet: calc.pallet_type_id,
              margin: calc.margin
            }
          });
        })
        .catch(error => {
          has_error.value = true;
          errors.value = error.response.data.message;
        });
    }

    function makeEditable(e: any) {
      e.preventDefault();
      const xs = document.getElementsByTagName("input");

      for (let i = 0; i < xs.length; i++) {
        xs[i].removeAttribute("readonly");
      }
    }

    function backButtonPopulate(query: any) {
      calc.user_id = query.user;
      calc.product_id = query.product;
      calc.state = query.state;
      calc.raw_cost = query.raw_cost;
      calc.product_packability = query.packability;
      calc.prod_cost = query.cost;
      calc.pack_weight = query.weight;
      calc.pack_count = query.count;
      calc.primary_packaging = query.primary;
      calc.labelling = query.labelling;
      calc.box_type = query.type;
      calc.box_weight = query.box_weight;
      calc.pallet_type_id = query.pallet;
      calc.margin = query.margin;
    }

    onMounted(() => {
      http.get(`/api/users?role=customer&fetch=all`).then((res: any) => {
        customers.value = res.data;
      });

      http.get(`/api/costs?fetch=all`).then((res: any) => {
        costs.value = res.data;
      });

      http.get(`/api/products?fetch=all`).then((res: any) => {
        products.value = res.data;
      });

      http.get(`/api/pallet-types?fetch=all`).then((res: any) => {
        pallets.value = res.data;
      });
      Object.keys(router.currentRoute.value.query).length !== 0 &&
        backButtonPopulate(router.currentRoute.value.query);
    });

    return {
      user,
      customers,
      costs,
      products,
      pallets,
      viewCalculation,
      has_error,
      errors,
      makeCalculation,
      calc,
      setProduct,
      setCosts,
      states,
      setPackability,
      makeEditable,
      setWeight,
      getCosts
    };
  }
});
</script>
